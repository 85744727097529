import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

function Seo({ description, lang, meta, keywords, title, image, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            keywords
            instagramUsername
            location
            organization {
              name
              url
              logo
              address {
                streetAddress
                addressLocality
                addressRegion
                postalCode
                addressCountry
              }
              priceRange
              currenciesAccepted
              paymentAccepted
              geo {
                latitude
                longitude
              }
            }
            social {
              instagram
            }
            businessHours {
              monday
              tuesday
              wednesday
              thursday
              friday
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;
  const defaultImage = `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`;
  const metaImage = image ? `${site.siteMetadata.siteUrl}${image}` : defaultImage;
  const org = site.siteMetadata.organization;
  const hours = site.siteMetadata.businessHours;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords.join(`, `),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:alt`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:locale`,
          content: 'en_US',
        },
        {
          property: `article:author`,
          content: site.siteMetadata.social.instagram,
        },
        {
          property: `business:contact_data:street_address`,
          content: org.address.streetAddress,
        },
        {
          property: `business:contact_data:locality`,
          content: org.address.addressLocality,
        },
        {
          property: `business:contact_data:region`,
          content: org.address.addressRegion,
        },
        {
          property: `business:contact_data:postal_code`,
          content: org.address.postalCode,
        },
        {
          property: `business:contact_data:country_name`,
          content: org.address.addressCountry,
        },
        {
          property: `place:location:latitude`,
          content: org.geo.latitude,
        },
        {
          property: `place:location:longitude`,
          content: org.geo.longitude,
        },
        {
          property: `business:hours:day`,
          content: 'monday through friday',
        },
        {
          property: `business:hours:start`,
          content: '09:00',
        },
        {
          property: `business:hours:end`,
          content: '17:00',
        },
        {
          property: `business:price_range`,
          content: org.priceRange,
        },
        {
          property: `business:currency`,
          content: org.currenciesAccepted,
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0'
        }
      ].concat(meta)}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: org.name,
            image: `${site.siteMetadata.siteUrl}${org.logo}`,
            '@id': site.siteMetadata.siteUrl,
            url: org.url,
            address: {
              '@type': 'PostalAddress',
              ...org.address
            },
            geo: {
              '@type': 'GeoCoordinates',
              ...org.geo
            },
            priceRange: org.priceRange,
            currenciesAccepted: org.currenciesAccepted,
            paymentAccepted: org.paymentAccepted,
            openingHoursSpecification: [
              {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                opens: '09:00',
                closes: '17:00'
              }
            ]
          })
        }
      ]}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  image: null,
  pathname: null,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string,
};

export default Seo;
